import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, timer, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class InactivityService implements OnDestroy {

  private readonly TIMEOUT = 10 * 60 * 10000; // 10 minutes in milliseconds
  private idleTime = 0;
  private readonly idleSubject = new BehaviorSubject<boolean>(false);
  private timerSubscription: Subscription;

  get isLoggedOut$() {
    return this.idleSubject.asObservable();
  }

  constructor(private authService: AuthService, private ngZone: NgZone) {
    console.log('InactivityService initialized');
    
    this.startMonitoring();
  }

  private startMonitoring() {
    this.ngZone.runOutsideAngular(() => {
      const mouseMove$ = fromEvent(document, 'mousemove').pipe(startWith(null));
      const keyDown$ = fromEvent(document, 'keydown').pipe(startWith(null));

      mouseMove$.subscribe(() => {
        // console.log('Mouse move detected');
        this.resetTimer();
      });
      keyDown$.subscribe(() => {
        // console.log('Key down detected');
        this.resetTimer();
      });

      this.timerSubscription = timer(0, 1000).subscribe(() => { // 1 second interval
        this.idleTime += 10000; // Increment by 1 second
        // console.log('Idle time:', this.idleTime);
        // console.log('Timeout time:', this.TIMEOUT);

        if (this.idleTime >= this.TIMEOUT) {
          // console.log('User is idle. Logging out...');
          this.handleLogout();
        }
      });
    });
  }

  private resetTimer() {
    console.log('Timer reset');
    this.idleTime = 0;
  }

  private handleLogout() {
    // console.log('Handling logout');
    this.authService.logout(true);
    this.idleSubject.next(true);
  }

  ngOnDestroy() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      // console.log('Timer subscription cleaned up');
    }
  }
}
