import { Component, ChangeDetectorRef } from '@angular/core';
import { RewardCriteriaService } from 'src/app/_services/reward-criteria.service';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/_services/loading.service';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent {
  selectedAdmin: any | null = null;
  rows: any[] = []; // List of users or admins
  messageText: string = '';
  imagePreview: string | null = null;
  uploadedSlip:any;
  messages: any[] = []; // Store the fetched messages

  constructor(
    private router: Router,
    private alertController: AlertController,
    public loading: LoadingService,
    private rewardCriteriaService: RewardCriteriaService,
    private authService: AuthService,
    private cdr: ChangeDetectorRef // Inject ChangeDetectorRef
  ) {
    this.initializeData(); // Initialize data based on user type
  }

  // Initialize data based on the logged-in user type
  initializeData(): void {
    const userType = this.authService.currentUser.userType;

    if (userType === 6) { // User logged in
      this.refreshAdminList();
    } else if (userType === 2) { // Admin logged in
      this.refreshClientList();
    }
  }

  // Fetch admin/client list and populate data
  refreshClientList(): void {
    let searchQuery = "?userType=6"; // Fetch users
    searchQuery += `&type=${this.authService.currentUser.type}`;

    this.rewardCriteriaService.getListOfAllUsers(searchQuery).subscribe(
      resp => {
        if (resp.errorcode === 0 && resp.resultObj && Array.isArray(resp.resultObj)) {
          this.rows = resp.resultObj.map(user => ({
            id: user.id,
            name: `${user.firstName}`,
            email: user.email,
            profilePicture: 'https://www.pikpng.com/pngl/b/80-805068_my-profile-icon-blank-profile-picture-circle-clipart.png',
            status: 'Online',
            messages: [] // Initialize empty messages array
          }));
        } else {
          console.error('Error fetching client list:', resp.message);
          this.rows = [];
        }
      },
      err => {
        console.error('Error fetching client list:', err);
        this.rows = [];
      }
    );
  }

  refreshAdminList(): void {
    let searchQuery = "?userType=2"; // Fetch admins
    searchQuery += `&type=${this.authService.currentUser.type}`;

    this.rewardCriteriaService.getListOfAllAdmins(searchQuery).subscribe(
      resp => {
        if (resp.errorcode === 0) {
          this.rows = resp.resultObj.map((admin: any) => ({
            id: admin.id,
            name: `${admin.firstName} ${admin.lastName}`,
            email: admin.email,
            profilePicture: 'https://example.com/default-profile.png',
            status: 'Online',
            messages: [] // Initialize empty messages array
          }));
        } else {
          console.error('Error fetching admin list:', resp.message);
        }
      },
      err => {
        console.error('Error fetching admin list:', err);
      }
    );
  }

  // Call this method when an admin is selected
  selectAdmin(admin: any): void {
    this.selectedAdmin = admin;
  
    // Clear previous messages
    this.messages = [];
    
    // Fetch the logged-in user's ID and the selected admin's ID
    const senderId = this.authService.currentUser.userId;
    const recipientId = admin.id;
  
    // Fetch messages between user and selected admin
    this.getMessages(); 
  }
  

  getMessages(): void {
    if (!this.selectedAdmin) return;
  
    const senderId = this.authService.currentUser.userId;
    const recipientId = this.selectedAdmin.id;
  
    // Call the API to fetch messages between sender and recipient
    this.rewardCriteriaService.getMessages(senderId, recipientId).subscribe(
      response => {
        
          // Log the response to confirm that messages are received
          console.log('Messages received:', response.data);
  
          // Assign fetched messages to the messages array
          this.messages = response.data; 
          
          // Optionally assign them to selectedAdmin's messages as well
          this.selectedAdmin.messages = this.messages;
  
          // Trigger change detection to update the UI
          this.cdr.detectChanges();
       
      },
      err => {
        console.error('Error fetching messages:', err);
      }
    );
  }
  

  // Handle sending a message
  sendMessage(fileInput: any): void {
    if (!this.selectedAdmin || !this.messageText.trim()) return; // Prevent empty message

    const messageData = {
      senderId: this.authService.currentUser.userId, // Logged-in user's ID
      recipientId: this.selectedAdmin.id, // Admin's ID
      messageText: this.messageText.trim(),
      imageUrl: this.uploadedSlip || null // Image if available
    };

    let fileForm = new FormData();

    fileForm.append("file", this.uploadedSlip);
    fileForm.append("senderId", this.authService.currentUser.userId);
    fileForm.append("recipientId", this.selectedAdmin.id);
    fileForm.append("messageText", this.messageText.trim());
    fileForm.append("type", this.authService.currentUser.type);
    fileForm.append("send_by", this.authService.currentUser.type);



    // Call the API to store the message in the database
    this.rewardCriteriaService.sendMessage(fileForm).subscribe(
      response => {
        if (response.errorcode === 0) {
          // Message stored successfully, update UI
          this.selectedAdmin.messages.push({
            messageText: this.messageText.trim(),
            image: this.imagePreview,
            sentBy: 'user' // Mark this message as sent by the user
          });

          // Clear form fields
          this.messageText = '';
          this.imagePreview = null;
          fileInput.value = '';
          this.getMessages(); // Fetch updated messages to refresh list
        } else {
          console.error('Failed to send message:', response.message);
        }
      },
      error => {
        console.error('Error sending message:', error);
      }
    );
  }

  // File handling methods
  handleFile = (file: File) => {
    const reader = new FileReader();
      // this.uploadedSlip= reader.result;
      this.uploadedSlip = file;

    reader.onloadend = () => {
      this.imagePreview = reader.result as string;
    };
    reader.readAsDataURL(file);
  }

  onDrop = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer?.files[0];
    if (file && file.type.startsWith('image/')) {
      this.handleFile(file);
    }
  }

  onDragOver = (event: DragEvent) => {
    event.preventDefault();
  }

  onFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      this.handleFile(file);
      event.target.value = ''; // Clear the file input after selection
    }
  }

  downloadImage(imageUrl: string) {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = imageUrl.substring(imageUrl.lastIndexOf('/') + 1); // Set file name as the last part of the URL
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link after downloading
  }

  
  closeMenu = () => {
    this.selectedAdmin = null;
    this.imagePreview = null;
  }
}
