import { AuthService } from 'src/app/_services/auth.service';
import { Component, OnInit } from '@angular/core';
import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { StatusBar } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { Geolocation } from '@capacitor/geolocation';
import { BehaviorSubject } from 'rxjs';
import { App } from '@capacitor/app';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { InactivityService } from './services/inactivity.service';

interface User {
  userId: string;
  name: String;
  email: String;
  userType: Number;
  ownerType: String;
  token: String;
  profileImageUrl: string;
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  selectedMenu: any;
  private authUserSub: BehaviorSubject<User>;

  constructor(
    private platform: Platform,
    private translate: TranslateService,
    public menuController: MenuController,
    private router: Router,
    private authService: AuthService,
    public toastController: ToastController,
    private modalController: ModalController,
    public afMessaging: AngularFireMessaging,
    private alertController: AlertController,
    private inactivityService: InactivityService
  ) {
    this.initializeApp();
    this.listen();
  }

  ngOnInit() {
    this.inactivityService.isLoggedOut$.subscribe(isLoggedOut => {
      if (isLoggedOut) {
        this.router.navigate(['/login']);
      } else {
        // console.log('User has been logged in due to inactivity');
      }
    });
  }

  openMenu() {
    this.menuController.open('end');
  }


  initializeApp() {
    this.platform.ready().then(async () => {
      App.addListener('appStateChange', ({ isActive }) => {
        if (isActive && this.authService.getFCMToken() != null && this.authService.isLoggedIn === true) {
          this.authService.validateLoginAPI();
        }
      });

      setTimeout(() => {
        SplashScreen.hide({
          fadeOutDuration: 1000
        });
      }, 2000);

      if (Capacitor.getPlatform() === 'ios') {
        StatusBar.setOverlaysWebView({ overlay: true });
      }

      this.translate.setDefaultLang('en');

      this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
        this.translate.setDefaultLang(event.lang);
      });

      const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

      if (isPushNotificationsAvailable) {
        this.initializeFirebase();
      }
    });
  }

  async presentToast(notification: any) {
    if (!notification?.message) {
      const toast = await this.toastController.create({
        message: notification.message,
        header: notification.title,
        duration: 3000,
        position: 'top'
      });
      toast.present();
    }
  }

  initializeFirebase() {
    console.log('Initializing Firebase');

    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        PushNotifications.register();
      } else {
        console.error('Push notification permission not granted');
      }
    });

    PushNotifications.addListener('registration', (token: Token) => {
      this.authService.setFCMToken(token.value);
      this.authService.validateLogin();
    });

    PushNotifications.addListener('registrationError', (error: any) => {
      console.error('Push registration error:', JSON.stringify(error));
    });

    PushNotifications.addListener('pushNotificationReceived', async (notificationObject: PushNotificationSchema) => {
      const { data } = notificationObject;
      if (data.notification_type === 'reminder' && Capacitor.getPlatform() === 'android') {
        await this.authService.openModal(notificationObject.data);
      } else {
        if (Capacitor.getPlatform() === 'android') {
          await this.presentToast(notificationObject);
        }
      }
      if (data.notification_type === 'logout') {
        this.authService.logout(true);
      }
    });

    PushNotifications.addListener('pushNotificationActionPerformed', async (notification: ActionPerformed) => {
      const { data } = notification.notification;
      if (data.notification_type === 'appointment_requested' || data.notification_type === 'appointment_approved'
        || data.notification_type === 'appointment_cancelled' || data.notification_type === 'appointment_reschedule') {
        this.router.navigate(['/patient/appointments']);
      } else if (data.notification_type === 'reward_redeemed' || data.notification_type === 'reward_added') {
        this.router.navigate(['/patient/reward-history']);
      } else if (data.notification_type === 'basic_subscription') {
        this.router.navigate(['/patient/my-membership']);
      } else if (data.notification_type === 'reminder') {
        console.log('Notification object', notification);
      }
    });
  }

  listen() {
    this.afMessaging.messages.subscribe((message) => {
      const { title, body } = message['notification'];
      const { notification_type, notification_type_id } = message['data'];

      this.handleNotification(title, body, notification_type, notification_type_id);
    });
  }

  handleNotification(title: string, body: string, notification_type: string, notification_type_id: string) {
    let checkNotification = 'Notification';
    if (notification_type === 'reminder') {
      checkNotification = 'Reminder';
    }
    this.alertController.create({
      header: checkNotification,
      subHeader: title,
      message: body,
      buttons: [
        'Cancel',
        {
          text: 'OK',
          handler: () => {
            let userType = localStorage.getItem('authUser');
            let preurl = 'patient';

            if (JSON.parse(userType).userType === 2) {
              preurl = 'provider';
            }
            if (notification_type === 'appointment_requested' || notification_type === 'appointment_approved'
              || notification_type === 'appointment_cancelled' || notification_type === 'appointment_reschedule') {
              this.router.navigate(['/' + preurl + '/appointments']);
            } else if (notification_type === 'reward_redeemed' || notification_type === 'reward_added') {
              this.router.navigate(['/' + preurl + '/reward-history']);
            } else if (notification_type === 'basic_subscription') {
              this.router.navigate(['/' + preurl + '/my-membership']);
            } else if (notification_type === 'reminder') {
              this.router.navigate(['/' + preurl + '/reminder-detail', notification_type_id]);
            }
          }
        }
      ]
    }).then(res => {
      res.present();
    });
  }
}
